import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cards from 'react-credit-cards';
import { toast } from 'react-toastify';
import UserWalletAddress from '../components/WithdrawCrypto/UserWalletAddress';
import { GetCoinImg } from '../helpers/getCoinImg';
import { getCryptoCurrency } from '../redux/currencies/currencyActions';
import { getNetwork } from '../redux/networks/networkActions';
import { getWallets } from '../redux/addresses/externalWalletActions';
import { getCards } from '../redux/cards/creditCardActions';
import { getWithdrawFee, resetWithdrawFee } from '../redux/withdrawFee/withdrawFeeActions';
import { clearWithdraw, submitWithdraw } from '../redux/externalTransactions/externalTransactionActions';
import { clearFiatWithdraw, submitFiatWithdraw } from '../redux/externalFiatTransactions/externalFiatTransactionActions';
import { clearBankWithdraw, submitBankWithdraw } from '../redux/externalBankTransactions/externalBankTransactionActions';
import { getBankAccounts } from '../redux/bankAccounts/bankAccountActions';
import GetAccountData from '../components/GetAccountData';
import { getAccount } from '../redux/account/accountActions';
import FullPageLoader from '../components/FullPageLoader/fullPageLoader';
import AddAddress from '../components/WithdrawCrypto/AddAddress';
import 'react-credit-cards/es/styles-compiled.css';
import UserCards from '../components/WithdrawCrypto/UserCards';
import AddCard from '../components/WithdrawCrypto/AddCard';
import AddBankAccount from '../components/WithdrawCrypto/AddBankAccount';
import UserBankAccounts from '../components/WithdrawCrypto/UserBankAccounts';
import { socketConnection } from '../redux/apiHelper';
import { FrequentlyAskedQuestion } from '../components/FrequentlyAskedQuestion/FrequentlyAskedQuestion';

function WithdrawCryptoPage() {
  const { t } = useTranslation();
  const { symbol } = useParams();
  const [show, setShow] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showAddCards, setShowAddCards] = useState(false);
  const [showBankAccount, setShowBankAccount] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseAddr = () => setShowAddAddress(false);
  const handleShowAddr = () => setShowAddAddress(true);

  const handleCloseCard = () => setShowCard(false);
  const handleShowCard = () => setShowCard(true);
  const handleShowBank = () => setShowBank(true);
  const handleCloseBank = () => setShowBank(false);
  const handleCloseCC = () => setShowAddCards(false);
  const handleShowCC = () => setShowAddCards(true);
  const handleShowBankAccount = () => setShowBankAccount(true);
  const handleCloseBankAccount = () => setShowBankAccount(false);

  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [haveCoins, setHaveCoins] = useState(0);
  const [withdrawCoins, setWithdrawCoins] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationCC, setShowConfirmationCC] = useState(false);
  const [showConfirmationBank, setShowConfirmationBank] = useState(false);
  const [depositCoin, setDepositCoin] = useState(true);
  const [depositCoin2, setDepositCoin2] = useState(false);
  const [depositCoin3, setDepositCoin3] = useState(false);
  const [number] = useState('');
  const [name] = useState('');
  const [expiry] = useState('');
  const [cvc] = useState('');
  const [focus] = useState('');

  const [selectedCard, setSelectedCard] = useState([]);
  const [selectedBank, setSelectedBank] = useState([]);

  const handleCloseConfirmation = () => setShowConfirmation(false);
  const handleCloseConfirmationCC = () => setShowConfirmationCC(false);
  const handleCloseConfirmationBank = () => setShowConfirmationBank(false);
  const handleShowConfirmation = () => setShowConfirmation(true);
  const handleShowConfirmationCC = () => setShowConfirmationCC(true);
  const handleShowConfirmationBank = () => setShowConfirmationBank(true);

  const dispatch = useDispatch();
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const externalWallets = useSelector((state) => state.externalWallets?.externalWallets);
  const creditCards = useSelector((state) => state.creditCards);
  const bankAccounts = useSelector((state) => state.bankAccounts?.bankAccounts);
  const networks = useSelector((state) => state.networks?.networks);
  const userId = useSelector((state) => state.user?.user?._id);
  const amounts = useSelector((state) => state.accounts?.account?.amounts);
  const withdrawFee = useSelector((state) => state.withdrawFee?.withdrawFee);
  const withdrawn = useSelector((state) => state.externalTransactions?.withdrawn);
  const fiatWithdrawn = useSelector((state) => state.externalFiatTransactions?.fiatWithdrawn);
  const bankWithdrawn = useSelector((state) => state.externalBankTransactions?.bankWithdrawn);
  const bankWithdrawMessage = useSelector((state) => state.externalBankTransactions?.bankWithdrawMessage);
  const error = useSelector((state) => state.externalTransactions?.error);
  const withdrawMsg = useSelector((state) => state.externalTransactions?.transaction?.message);
 
  useEffect(() => {
    dispatch(getCryptoCurrency());
    dispatch(getNetwork());
    if (userId) {
      dispatch(getWallets(userId));
      dispatch(getCards(userId));
      dispatch(getBankAccounts(userId));
    }
  }, [userId]);

  const handleWithdraw = async () => {
    if (parseFloat(withdrawCoins) <= 0) {
      Swal.fire({
        text: t('messages.min_withdrawal_warning'),
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    } else {
      const data = {
        userId,
        currencyId: selectedCurrency?._id,
        sendToAddress: selectedAddress?.address,
        amount: withdrawCoins.toString(),
      };

      await dispatch(submitWithdraw(data));
      setLoader(true);
    }
  };

  const handleWithdrawCard = async () => {
    if (parseFloat(haveCoins) <= 0) {
      Swal.fire({
        text: t('messages.min_withdrawal_warning'),
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    } else {
      const data = {
        userId,
        currencyId: selectedCurrency?._id,
        sendToCard: selectedCard?.card,
        deducted: parseFloat(withdrawCoins).toPrecision(8),
        coins: withdrawCoins.toString(),
      };

      await dispatch(submitFiatWithdraw(data));
      setLoader(true);

      // connect socket and emit notification
      await socketConnection();
    }
  };

  const handleWithdrawBank = async () => {
    if (parseFloat(haveCoins) <= 0) {
      Swal.fire({
        // title: 'Success',
        text: t('messages.min_withdrawal_warning'),
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    } else {
      const data = {
        userId,
        currencyId: selectedCurrency?._id,
        sendToIban: selectedBank?.iban,
        sendToAddress: selectedBank?.bankAddress,
        sendToAccountNumber: selectedBank?.accountNumber,
        sendToSwiftCode: selectedBank?.swiftCode,
        deducted: parseFloat(withdrawCoins).toPrecision(8),
        coins: withdrawCoins.toString(),
      };

      await dispatch(submitBankWithdraw(data)).then(
        () => setLoader(false),
      );
      setLoader(true);

      // connect socket and emit notification
      await socketConnection();
    }
  };

  const getWithdrawInfo = (network) => {
    setShowWithdraw(true);
    const data = {
      networkId: network._id,
      currencyId: selectedCurrency?._id,
    };
    dispatch(getWithdrawFee(data));
  };

  useEffect(() => {
    if (currencyData?.length > 0 && amounts?.length > 0) {
      const defCurrency = currencyData?.find((cur) => cur?.symbol === 'USD');
      setSelectedCurrency(defCurrency);
    }
  }, [currencyData, amounts]);

  const clearWithdrawFee = () => {
    dispatch(resetWithdrawFee());
  };

  const handleSelectedCoin = (coin) => {
    setSelectedCurrency(coin);
    if (coin) setHaveCoins(amounts?.find((row) => row.currencyId === coin._id).amount);
    setSelectedNetwork([]);
    setSelectedAddress([]);
    setSelectedCard([]);
    setSelectedBank([]);
    setShowWithdraw(false);
    setWithdrawCoins(0);
    clearWithdrawFee();
  };

  const handleWithdrawLimit = () => {
    const validNumber = /^\d*\.?\d*$/;
    const feeAmount = (withdrawCoins * (withdrawFee?.fee || 0 || 0)) / 100;

    if (
      !withdrawCoins.toString().match(validNumber)
      || parseFloat(withdrawCoins)
        > parseFloat(
          (parseFloat(haveCoins) - feeAmount >= 0 ? parseFloat(haveCoins) - feeAmount : 0) < withdrawFee?.max
            ? parseFloat(haveCoins) - feeAmount >= 0
              ? parseFloat(haveCoins) - feeAmount
              : 0
            : withdrawFee?.max,
        )
      || parseFloat(withdrawCoins) < parseFloat(withdrawFee?.min)
    ) {
      let textMesasge = '';
      const selectedCurrSymbol = selectedCurrency && selectedCurrency?.symbol ? selectedCurrency?.symbol : '';
      if (!withdrawCoins.toString().match(validNumber)) {
        textMesasge = t('messages.invalid_number_warning');
      } else if (parseFloat(withdrawCoins) > parseFloat(haveCoins) - parseFloat(feeAmount)) {
        textMesasge = t('messages.not_enough_balance_for_withdraw_with_fee_warning', {
          current_balance: `${haveCoins} ${selectedCurrSymbol}`,
          withdraw_amount: `${parseFloat(withdrawCoins)} ${selectedCurrSymbol}`,
          fee: `${feeAmount} ${selectedCurrSymbol}`,
        });
      } else if (parseFloat(withdrawCoins) > parseFloat(withdrawFee?.max)) {
        textMesasge = t('messages.max_withdraw_warning', { amount: `${withdrawFee?.max} ${selectedCurrSymbol}` });
      } else if (parseFloat(withdrawCoins) < parseFloat(withdrawFee?.min)) {
        textMesasge = t('messages.min_withdraw_warning', { amount: `${withdrawFee?.min} ${selectedCurrSymbol}` });
      }

      setWithdrawCoins(0);
      Swal.fire({
        text: textMesasge,
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    }
  };

  const handleWithdrawLimitFiat = () => {
    const validNumber = /^\d*\.?\d*$/;

    if (!withdrawCoins.toString().match(validNumber) || parseFloat(withdrawCoins) > parseFloat(parseFloat(haveCoins))) {
      let textMesasge = '';
      const selectedCurrSymbol = selectedCurrency && selectedCurrency?.symbol ? selectedCurrency?.symbol : '';
      if (!withdrawCoins.toString().match(validNumber)) {
        textMesasge = t('messages.invalid_number_warning');
      } else if (parseFloat(withdrawCoins) > parseFloat(haveCoins)) {
        textMesasge = t('messages.not_enough_balance_for_withdraw_warning', { 
          current_balance: `${haveCoins} ${selectedCurrSymbol}`,
          withdraw_amount: `${parseFloat(withdrawCoins)} ${selectedCurrSymbol}`,
        });
      }

      setWithdrawCoins(0);
      Swal.fire({
        text: textMesasge,
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    }
  };

  const depositCoinHandler = () => {
    setDepositCoin(true);
    setDepositCoin2(false);
    setDepositCoin3(false);
  };
  const depositCoinHandler2 = () => {
    setDepositCoin(false);
    setDepositCoin2(true);
    setDepositCoin3(false);
  };
  const depositCoinHandler3 = () => {
    setDepositCoin(false);
    setDepositCoin2(false);
    setDepositCoin3(true);
  };

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(clearWithdraw());
      dispatch(clearFiatWithdraw());
      dispatch(clearBankWithdraw());
    } else if (withdrawn) {
      setLoader(false);
      Swal.fire({
        title: t('labels.success'),
        text: withdrawMsg,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(() => {
        dispatch(getAccount(userId));
        dispatch(clearWithdraw());
        setSelectedCurrency([]);
        handleSelectedCoin(null);
      });
    } else if (fiatWithdrawn) {
      setLoader(false);
      Swal.fire({
        title: t('labels.success'),
        text: withdrawMsg,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(() => {
        dispatch(getAccount(userId));
        dispatch(clearFiatWithdraw());
        setSelectedCurrency([]);
        handleSelectedCoin(null);
      });
    } else if (bankWithdrawn) {
      setLoader(false);
      Swal.fire({
        title: t('labels.success'),
        text: bankWithdrawMessage,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(() => {
        dispatch(getAccount(userId));
        dispatch(clearBankWithdraw());
        setSelectedCurrency([]);
        handleSelectedCoin(null);
      });
    }
  }, [withdrawn, fiatWithdrawn, bankWithdrawn, error]);

  useEffect(() => {
    if (currencyData) {
      const found = currencyData?.find((currency) => currency.symbol === symbol);
      handleSelectedCoin(found);
    }
  }, [currencyData]);

  const handleWithdrawButton = () => {
    if (withdrawCoins > haveCoins) {
      toast.error(t('messages.not_enough_balance_for_withdraw_warning', { current_balance: `${haveCoins} ${selectedCurrency?.symbol}`, withdraw_amount: `${withdrawCoins} ${selectedCurrency?.symbol}` }));
      return;
    }

    handleShowConfirmation();
  };

  return loader ? (
    <FullPageLoader />
  ) : (
    <>
      {/* <Overview />
        <RecentDeposit /> */}

      <section className="header-padding withdrawal-page">
        <GetAccountData />

        <div className="container-fluid custom-box padding50">
          <div className="d-flex justify-content-center align-items-center flex-md-row flex-column">
            <div className="d-flex align-items-center mb-lg-0 mb-3">
              <i className="fa fa-angle-left me-lg-4 me-3 left-angle" />
              <h3 className="mb-0 text-light" style={{ textTransform: 'uppercase' }}>{t('labels.withdrawal')}</h3>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-8">
              <div className="deposit-col">
                <div className="deposit-coin">
                  <button type="button" className="payopt" onClick={depositCoinHandler}>
                    {t('labels.coin')}
                  </button>
                  {/* <button type="button" className="payopt" onClick={depositCoinHandler2}>
                    Credit Card
                  </button>
                  <button type="button" className="payopt" onClick={depositCoinHandler3}>
                    Bank
                  </button> */}
                  {depositCoin && (
                    <>
                      <div className="dropdown deposit-dropdown" style={{ maxWidth: '500px' }}>
                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ width: '100%' }}>
                          <div className="d-flex justify-content-between">
                            <p className="coin-name">{selectedCurrency?.symbol ? selectedCurrency?.symbol : symbol}</p>
                            <div className="coin-details d-flex align-items-center">
                              <p className="detail">
                                (
                                {selectedCurrency?.name ? selectedCurrency?.name : t('labels.select')}
                                )
                              </p>
                              <p className="dd-arrow" />
                            </div>
                          </div>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          {
                          currencyData && currencyData.length 
                            ? currencyData.map((currency) => (
                              <li key={currency._id} onClick={() => handleSelectedCoin(currency)}>
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    handleSelectedCoin(currency);
                                  }}
                                >
                                  <img src={GetCoinImg(currency?.symbol)} alt="" className="img-fluid coin-img pe-1" />
                                  {currency.name}
                                </a>
                              </li>
                            ))
                            : (<p className="text-light">{t('messages.no_currencies_found')}</p>)
                          }
                        </ul>
                      </div>
                      <div className="wallet-address-amount-withdrawal">
                        <div className="wallet-address">
                          <div className="address-add">
                            <p className="mb-0 text-white">{t('labels.wallet_address')}</p>
                            <button type="button" onClick={handleShow} className="btn add-address-btn">
                              {t('labels.add')}
                            </button>
                          </div>
                          <div className="dropdown wallet-address-dd">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              {selectedAddress && selectedAddress.address ? selectedAddress.name : `${t('labels.select_wallet_address')}...`}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              {externalWallets && externalWallets.filter((wallet) => wallet.currencyId === selectedCurrency?._id).length ? (
                                externalWallets
                                  .filter((wallet) => wallet.currencyId === selectedCurrency?._id)
                                  .map((wallet) => (
                                    <li key={wallet._id}>
                                      <a className="dropdown-item" key={wallet._id} onClick={() => setSelectedAddress(wallet)}>
                                        {wallet.name}
                                      </a>
                                    </li>
                                  ))
                              ) : (
                                <li>
                                  <a className="dropdown-item">{t('messages.no_addresses_added')}</a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                  
                        {selectedAddress && selectedAddress.status ? (
                          <div className="amount-withdrawal">
                            <div className="withdrawal-amount">
                              <p className="mb-0 text-white">{t('labels.amount_withdrawable')}</p>
                              <p className="text-white-light mb-0">{`${t('labels.amount')}: ${haveCoins} ${selectedCurrency && selectedCurrency?.symbol ? selectedCurrency?.symbol : ''}`}</p>
                            </div>
                            <div className="input-all">
                              <input
                                type="number"
                                max={haveCoins || 0}
                                onChange={({ target: { value } }) => setWithdrawCoins(Number(value))}
                                onBlur={() => handleWithdrawLimit()}
                                value={withdrawCoins}
                              />
                              <button
                                type="button"
                                className="btn text-green"
                                onClick={() => {
                                  setWithdrawCoins(haveCoins || 0);
                                }}
                              >
                                {t('labels.all')}
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {
                        !!withdrawCoins && (
                          <button 
                            type="button" 
                            className="btn enter-btn3" 
                            onClick={() => handleWithdrawButton()}
                            disabled={loader}
                            style={{ textTransform: 'uppercase' }}
                          >
                            {t('labels.enter')}
                          </button>
                        )
                      }
                    </>

                  )}

                  <div className="deposit-coin3">
                    {/* <h2 onClick={depositCoinHandler3}>Bank</h2> */}

                    {depositCoin3 && (
                      <form className="ccdetailw">
                        {/* <h1>Bank Transfer</h1> */}

                        {depositCoin3 && (
                          <div className="dropdown deposit-dropdown">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <div className="d-flex justify-content-between">
                                <p className="coin-name">{selectedCurrency?.symbol ? selectedCurrency?.symbol : symbol}</p>
                                <div className="coin-details d-flex align-items-center">
                                  <p className="detail">
                                    (
                                    {selectedCurrency?.name ? selectedCurrency?.name : 'Select'}
                                    )
                                  </p>
                                  <p className="dd-arrow" />
                                </div>
                              </div>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              {currencyData && currencyData.length > 0 && currencyData.length ? (
                                currencyData
                                && currencyData.length > 0
                                && currencyData
                                  .filter((row) => row?.isFiat === true)
                                  .map((currency) => (
                                    <li key={currency._id} onClick={() => handleSelectedCoin(currency)}>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => {
                                          handleSelectedCoin(currency);
                                        }}
                                      >
                                        <img src={GetCoinImg(currency?.symbol)} alt="" className="img-fluid coin-img pe-1" />
                                        {currency.name}
                                      </a>
                                    </li>
                                  ))
                              ) : (
                                <p className="text-light"> No Currencies found! </p>
                              )}
                            </ul>
                          </div>
                        )}

                        <div className="row">
                          <div className="wallet-address">
                            <div className="address-add">
                              <p className="mb-0 text-white">Bank Account</p>
                              <button type="button" onClick={handleShowBank} className="btn add-address-btn">
                                Add
                              </button>
                            </div>
                            <div className="dropdown wallet-address-dd">
                              <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedBank && selectedBank.bankAddress ? selectedBank.name : 'Select Bank Account...'}
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                {bankAccounts && bankAccounts.filter((bank) => bank && bank.currencyId === selectedCurrency?._id).length ? (
                                  bankAccounts
                                  && bankAccounts
                                    .filter((bank) => bank && bank.currencyId === selectedCurrency?._id)
                                    .map((bank) => (
                                      <li>
                                        <a className="dropdown-item" key={bank._id} onClick={() => setSelectedBank(bank)}>
                                          {bank.name}
                                        </a>
                                      </li>
                                    ))
                                ) : (
                                  <li>
                                    <a className="dropdown-item">No Addresses Added</a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <br />
                        <br />
                        {selectedBank && selectedBank.status ? (
                          <>
                            <div className="amount-withdrawal">
                              <div className="withdrawal-amount">
                                <p className="mb-0 text-white">Amount Withdrawable</p>
                                <p className="text-white-light mb-0">
                                  Amount
                                  {haveCoins}
                                  {selectedCurrency && selectedCurrency?.symbol ? selectedCurrency?.symbol : ''}
                                </p>
                              </div>
                              <div className="input-all">
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setWithdrawCoins(e.target.value);
                                  }}
                                  onBlur={() => handleWithdrawLimitFiat()}
                                  value={withdrawCoins}
                                />
                                <button
                                  type="button"
                                  className="btn text-green"
                                  onClick={() => {
                                    setWithdrawCoins(parseFloat(haveCoins) - withdrawCoins / 100 >= 0 ? parseFloat(haveCoins) - withdrawCoins / 100 : 0);
                                  }}
                                >
                                  All
                                </button>
                              </div>
                            </div>
                            <br />
                            <button type="button" className="btn enter-btn3" onClick={() => handleShowConfirmationBank()} disabled={loader}>
                              ENTER
                            </button>
                          </>
                        ) : null}
                      </form>
                    )}
                  </div>

                  <div className="deposit-coin2">
                    {depositCoin2 && (
                      <div className="dropdown deposit-dropdown">
                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <div className="d-flex justify-content-between">
                            <p className="coin-name">{selectedCurrency?.symbol ? selectedCurrency?.symbol : symbol}</p>
                            <div className="coin-details d-flex align-items-center">
                              <p className="detail">
                                (
                                {selectedCurrency?.name ? selectedCurrency?.name : 'Select'}
                                )
                              </p>
                              <p className="dd-arrow" />
                            </div>
                          </div>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          {currencyData && currencyData.length > 0 && currencyData.length ? (
                            currencyData
                            && currencyData.length > 0
                            && currencyData
                              .filter((row) => row?.isFiat === true)
                              .map((currency) => (
                                <li key={currency._id} onClick={() => handleSelectedCoin(currency)}>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => {
                                      handleSelectedCoin(currency);
                                    }}
                                  >
                                    <img src={GetCoinImg(currency?.symbol)} alt="" className="img-fluid coin-img pe-1" />
                                    {currency.name}
                                  </a>
                                </li>
                              ))
                          ) : (
                            <p className="text-light"> No Currencies found! </p>
                          )}
                        </ul>
                      </div>
                    )}

                    {depositCoin2 && (
                      <div className="cardf">
                        <div>
                          <Cards number={number} name={name} expiry={expiry} cvc={cvc} focused={focus} />
                        </div>
                      </div>
                    )}

                    <br />
                    {depositCoin2 && (
                      <form className="ccdetailw">
                        {/* <div className="row">
                          <div className="col-sm-11">
                            <label for="name" className='carddet'>Card Number</label>
                            <input
                              type="tel"
                              className="form-controlcarddet"
                              value={number}
                              name="number"
                              maxLength="16"
                              pattern="[0-9]+"
                              onChange={(e) => {
                                SetNumber(e.target.value);
                              }}
                              onFocus={(e) => SetFocus(e.target.name)}
                            ></input>
                          </div>
                        </div> */}
                        <br />
                        <div className="row">
                          <div className="wallet-address">
                            <div className="address-add">
                              <p className="mb-0 text-white">Card Number</p>
                              <button type="button" onClick={handleShowCard} className="btn add-address-btn">
                                Add
                              </button>
                            </div>
                            <div className="dropdown wallet-address-dd">
                              <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedCard && selectedCard.card ? selectedCard.name : 'Select card number...'}
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                {creditCards && creditCards.filter((card) => card && card.currencyId === selectedCurrency?._id).length ? (
                                  creditCards
                                  && creditCards
                                    .filter((card) => card && card.currencyId === selectedCurrency?._id)
                                    .map((card) => (
                                      <li>
                                        <a className="dropdown-item" key={card._id} onClick={() => setSelectedCard(card)}>
                                          {card.name}
                                        </a>
                                      </li>
                                    ))
                                ) : (
                                  <li>
                                    <a className="dropdown-item">No Addresses Added</a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {selectedCard && selectedCard.status ? (
                            <div className="amount-withdrawal">
                              <div className="withdrawal-amount">
                                <p className="mb-0 text-white">Amount Withdrawable</p>
                                <p className="text-white-light mb-0">
                                  Amount
                                  {haveCoins}
                                  {selectedCurrency && selectedCurrency?.symbol ? selectedCurrency?.symbol : ''}
                                </p>
                              </div>
                              <div className="input-all">
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setWithdrawCoins(e.target.value);
                                  }}
                                  onBlur={() => handleWithdrawLimitFiat()}
                                  value={withdrawCoins}
                                />
                                <button
                                  type="button"
                                  className="btn text-green"
                                  onClick={() => {
                                    setWithdrawCoins(parseFloat(haveCoins) - withdrawCoins / 100 >= 0 ? parseFloat(haveCoins) - withdrawCoins / 100 : 0);
                                  }}
                                >
                                  All
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="row">
                          {selectedCard && selectedCard.status && parseFloat(withdrawCoins) ? (
                            <button type="button" className="btn enter-btn4" onClick={() => handleShowConfirmationCC()} disabled={loader}>
                              ENTER
                            </button>
                          ) : null}
                        </div>

                        {/* <div className="amount-withdrawal">
                          <div className="withdrawal-amount">
                            <p className="mb-0 text-white">Amount Withdrawable</p>
                            <p className="text-white-light mb-0">
                              Amount {haveCoins} {selectedCurrency && selectedCurrency?.symbol ? selectedCurrency?.symbol : ""}
                            </p>
                          </div>
                          <div className="input-all">
                            <input type="text"
                            max={(parseFloat(haveCoins) - ((withdrawCoins * ((withdrawCoins * (withdrawFee?.fee || 0)) / 100)) / 100) >= 0 ?
                            parseFloat(haveCoins) - ((withdrawCoins * (withdrawFee?.fee || 0)) / 100) : 0) < withdrawFee?.max ?
                            (parseFloat(haveCoins) - ((withdrawCoins * (withdrawFee?.fee || 0)) / 100) >= 0 ?
                            parseFloat(haveCoins) - ((withdrawCoins * (withdrawFee?.fee || 0)) / 100) : 0) : withdrawFee?.max}
                            min={withdrawFee?.min} onChange={(e) => { setWithdrawCoins(e.target.value) }} onBlur={() => handleWithdrawLimit()} value={withdrawCoins} />
                            <button type="button" className="btn text-green"
                              onClick={() => {
                                setWithdrawCoins((parseFloat(haveCoins) - ((withdrawCoins * (withdrawFee?.fee || 0)) / 100) >= 0 ?
                                 parseFloat(haveCoins) - ((withdrawCoins * (withdrawFee?.fee || 0)) / 100) : 0) < withdrawFee?.max ?
                                 (parseFloat(haveCoins) - ((withdrawCoins * (withdrawFee?.fee || 0)) / 100) >= 0 ?
                                 parseFloat(haveCoins) - ((withdrawCoins * (withdrawFee?.fee || 0)) / 100) : 0) : withdrawFee?.max) }}
                            >
                              All
                            </button>
                          </div>
                        </div>
                        <br />
                        <button type="button" className="btn enter-btn3" onClick={() => handleShowConfirmationCC()} disabled={loader}>ENTER</button> */}
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <FrequentlyAskedQuestion />
          </div>
        </div>
        <div className="batton" />
      </section>

      {/* ==================================== confirmation modal ============================== */}

      <Modal Modal className="withdrawal-modal" centered show={showConfirmation} onHide={handleCloseConfirmation}>
        <Modal.Header className="modal-main-heading" closeButton>
          <div className="modal-main-heading-content">
            <h5 className="modal-title" id="exampleModalLabel">
              {t('labels.are_you_sure')}
            </h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal-modal1 text-light">
            <p>
              <b>{`${t('labels.sending_to_address')}:`}</b>
              <p style={{ wordBreak: 'break-all' }}>{selectedAddress?.address}</p>
            </p>
            <p>
              <b>{`${t('labels.sending')}:`}</b>
              {withdrawCoins}
              {selectedCurrency?.symbol}
            </p>
            <br />
            <div className="d-flex justify-content-right">
              <button
                type="button"
                className="btn btn-success me-2"
                onClick={() => {
                  handleWithdraw();
                  handleCloseConfirmation();
                }}
              >
                {t('labels.send')}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleCloseConfirmation();
                }}
              >
                {t('labels.cancel')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== confirmation modal for CC ============================== */}

      <Modal Modal className="withdrawal-modal" centered show={showConfirmationCC} onHide={handleCloseConfirmationCC}>
        <Modal.Header className="modal-main-heading" closeButton>
          <div className="modal-main-heading-content">
            <h5 className="modal-title" id="exampleModalLabel">
              ARE YOU SURE?
            </h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal-modal1 text-light">
            <p>
              <b>Sending to Card: </b>
              <p style={{ wordBreak: 'break-all' }}>{selectedCard?.card}</p>
            </p>
            <p>
              <b>Sending: </b>
              {withdrawCoins}
              {selectedCurrency?.symbol}
            </p>
            <p>
              <b>Deducted from your Wallet: </b>
              {parseFloat(withdrawCoins).toPrecision(8)}
              {selectedCurrency?.symbol}
            </p>
            <br />
            <p>
              <b className="text-danger">Warning: </b>
              We will not be responsible if the coins are sent to a wrong address!!!
            </p>
            <div className="d-flex justify-content-right">
              <button
                type="button"
                className="btn form-btn text-capitalize"
                onClick={() => {
                  handleWithdrawCard();
                  handleCloseConfirmationCC();
                }}
              >
                YES, Send!
              </button>
              <button
                type="button"
                className="btn btn-danger text-capitalize ms-2"
                onClick={() => {
                  handleCloseConfirmationCC();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== confirmation modal for Bank Account ============================== */}

      <Modal Modal className="withdrawal-modal" centered show={showConfirmationBank} onHide={handleCloseConfirmationBank}>
        <Modal.Header className="modal-main-heading" closeButton>
          <div className="modal-main-heading-content">
            <h5 className="modal-title" id="exampleModalLabel">
              ARE YOU SURE?
            </h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal-modal1 text-light">
            <p>
              <b>Sending to Bank Account: </b>
              <p style={{ wordBreak: 'break-all' }}>{selectedBank?.iban}</p>
            </p>
            <p>
              <b>Sending: </b>
              {withdrawCoins}
              {selectedCurrency?.symbol}
            </p>
            <p>
              <b>Deducted from your Wallet: </b>
              {parseFloat(withdrawCoins).toPrecision(8)}
              {selectedCurrency?.symbol}
            </p>
            <br />
            <p>
              <b className="text-danger">Warning: </b>
              We will not be responsible if the coins are sent to a wrong address!!!
            </p>
            <div className="d-flex justify-content-right">
              <button
                type="button"
                className="btn form-btn text-capitalize"
                onClick={() => {
                  handleWithdrawBank();
                  handleCloseConfirmationBank();
                }}
              >
                YES, Send!
              </button>
              <button
                type="button"
                className="btn btn-danger text-capitalize ms-2"
                onClick={() => {
                  handleCloseConfirmationBank();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 1 ============================== */}

      <Modal className="withdrawal-modal" show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="withdrawal-modal1">
            <h4 className="text-white mb-0">{t('labels.addresses')}</h4>
            <div className="text-end">
              <button type="button" onClick={handleShowAddr} className="btn add-address-btn">
                {t('labels.add_address')}
              </button>
            </div>
            <br />
            <UserWalletAddress />
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 2 =============================== */}

      <Modal className="withdrawal-modal" show={showAddAddress} onHide={handleCloseAddr} centered backdrop="static" size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          <AddAddress handleCloseAddr={handleCloseAddr} />
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 1 Bank Account ============================== */}

      <Modal className="withdrawal-modal" show={showBank} onHide={handleCloseBank} centered size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="withdrawal-modal1">
            <h4 className="text-white mb-0">Bank Accounts</h4>
            <div className="text-end">
              <button type="button" onClick={handleShowBankAccount} className="btn add-address-btn">
                Add Bank Account
              </button>
            </div>
            <br />
            <UserBankAccounts />
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 2 Bank Account =============================== */}

      <Modal className="withdrawal-modal" show={showBankAccount} onHide={handleCloseBankAccount} centered backdrop="static" size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          <AddBankAccount handleCloseBankAccount={handleCloseBankAccount} />
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 1 CC ============================== */}

      <Modal className="withdrawal-modal" show={showCard} onHide={handleCloseCard} centered size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="withdrawal-modal1">
            <h4 className="text-white mb-0">Cards</h4>
            <div className="text-end">
              <button type="button" onClick={handleShowCC} className="btn add-address-btn">
                Add Card
              </button>
            </div>
            <br />
            <UserCards type="crypto" />
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 2 CC =============================== */}

      <Modal className="withdrawal-modal" show={showAddCards} onHide={handleCloseCC} centered backdrop="static" size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          <AddCard handleCloseCC={handleCloseCC} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WithdrawCryptoPage;

// unused methods
// const handleDate = (e) => {
//   SetMonth(e.target.value);
//   SetExpiry(e.target.value);
// };
// const handleExpiry = (e) => {
//   SetExpiry(month.concat(e.target.value));
// };
// const copyReferral = () => {
//   toast.success('Successfully copied!');
// };

// const handleSubmit = (event) => {
//   event.preventDefault();
//   alert(
//     `The name you entered was: ${Iban}, ${accountNumber}, ${bankName}, ${bankAddress}, ${swiftCode}`,
//   );
// };
